.video-container {
	position: relative;
	width: 100%;
	height: auto;
}

.video {
	width: 100%;
	height: auto;
	z-index: 1;
	position: absolute;
}

.overlay {
	position: absolute;
	top: 100;
	left: 0;
	width: 100%;
	height: 50%; /* Cover the bottom half */
	background-color: white;
	transform-origin: bottom left;
	transform: skewY(-30deg); /* Rotate by 30 degrees */
	z-index: 99;
}

.overlay2 {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 50%; /* Cover the bottom half */
	background-color: white;
	transform-origin: bottom right;
	transform: skewY(-30deg);
	z-index: 99;
}
